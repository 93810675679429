import React from 'react'
import { GetAllText, ReadingTime } from '../../../../utils/reading-time'
import parse from 'html-react-parser'
import { Link } from 'gatsby'

const RelatedArticleCard = ({ title, image, excerpt, uri, wordCount }) => {
  const time = wordCount && ReadingTime(GetAllText(wordCount))

  return (
    <Link to={uri} className={'w-full md:max-w-[436px]'}>
      <div className={'flex flex-col w-full gap-4'}>
        <div>
          <img
            className={'w-full rounded-xl object-cover aspect-[1.7/1]  '}
            src={image.publicUrl}
            alt={image.altText ? image.altText : ''}
          />
        </div>
        <h3 className={'text-3xl md:text-4xl font-bold'}>{title}</h3>
        <p className={'font-medium text-blue-dark text-sm'}>{time}</p>

        <div className={'flex flex-col justify-between gap-4 h-full'}>
          <div className={'text-base lg:text-lg'}>
            {parse(excerpt.substring(0, 200))}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default RelatedArticleCard
