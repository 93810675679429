import React from 'react'
import { Button } from '../../../common/button'
import PropTypes from 'prop-types'
import { isLoggedIn } from '../../../../services/authentication'

const Freewall = ({ introduction }) => {
  return (
    <>
      <div
        className={`relative h-[582px]
      ${isLoggedIn() && 'hidden'}
      `}
      >
        {introduction && (
          <div className={'sm-container mx-auto pt-12'}>{introduction}</div>
        )}
        <div className={'absolute bottom-0 '}>
          <div className={'bg-gradient-to-t from-gray-100 h-40 w-screen'} />
          <div
            className={
              'w-screen bg-gray-100 pt-8 px-6 pb-6 lg:pt-16 lg:pb-10 lg:px-12'
            }
          >
            <div
              className={
                'flex flex-col justify-center items-center gap-y-6 max-w-[436px] mx-auto'
              }
            >
              <h3
                className={
                  'text-blue-dark font-bold text text-3xl lg:max-w-[436px]'
                }
              >
                Logg inn for å lese videre
              </h3>
              <p className={'text-lg text-center'}>
                Som Proteket Online-medlem har du ubegrenset tilgang til kasus,
                statistikk og annen fagkunnskap, helt gratis.
              </p>
              <Button
                variant={'contained'}
                path={'/registrer-bruker'}
                isExternal={false}
                color={'orange'}
              >
                Bli medlem
              </Button>
              <Button variant={'text'} path={'/logg-inn'} isExternal={false}>
                Logg inn
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Freewall

Freewall.propTypes = {
  introduction: PropTypes.string,
}