import React, { useEffect, useState } from 'react'

export const GetAllText = (list) => {
  const [wordList, setWordList] = useState([])
  useEffect(() => {
    if (list.introductionText) {
      setWordList((wordList) => [...wordList, list.introductionText])
    } else if (list.mainContent?.wysiwygContent) {
      setWordList((wordList) => [...wordList, list.mainContent.wysiwygContent])
    } else if (list?.mainContent?.headingText) {
      setWordList((wordList) => [...wordList, list.mainContent.headingText])
    } else if (list?.summaryWidget?.summaryContent) {
      setWordList((wordList) => [
        ...wordList,
        list.summaryWidget.summaryContent,
      ])
    } else if (list?.summaryWidget?.summaryHeading) {
      setWordList((wordList) => [
        ...wordList,
        list.summaryWidget.summaryHeading,
      ])
    }
  }, [list])
  return wordList.join()
}

export const ReadingTime = (wordCount) => {
  const [theTime, setTheTime] = useState('')

  useEffect(() => {
    const wordsPerMinute = 225
    const words = wordCount.trim().split(' ').length
    const time = Math.ceil(words / wordsPerMinute)
    if (time <= 1) {
      setTheTime(time + ' minutt lesetid')
    } else setTheTime(time + ' minutter lesetid')
  }, [wordCount])

  return theTime
}
