import React from 'react'
import PropTypes from 'prop-types'
import { isLoggedIn } from '../../../../services/authentication'
import RelatedArticleCard from './card'

const RelatedArticles = ({ list }) => {
  const switcher = (item) => {
    if (item.fieldGroupName === 'related_cases_list') {
      return item.case
    } else return item.statisticsPost
  }

  const templateSwitcher = (item) => {
    if (item.fieldGroupName === 'related_cases_list') {
      return item.case.templateCase
    } else return item.statisticsPost.templateStatisticPost
  }

  return (
    <div
      className={`w-screen bg-white py-12 
            ${!isLoggedIn() && 'hidden'}
    `}
    >
      <div className={'xl-container mx-auto '}>
        <h4
          className={
            'text-3xl lg:text-4xl font-semibold md:text-center pb-6 md:pb-8 lg:pb-12'
          }
        >
          {list[0]?.fieldGroupName === 'related_cases_list'
            ? 'Andre kasus'
            : 'Annen statistikk'}
          {/*{!list[0] && 'Andre artikler'}*/}
        </h4>
        <div
          className={
            'flex flex-col md:flex-row items-top justify-center gap-6 lg:gap-x-12 '
          }
        >
          {list.map((item, index) => (
            <RelatedArticleCard
              key={index}
              title={switcher(item).title}
              image={switcher(item).featuredImage.node}
              link={switcher(item).uri}
              excerpt={switcher(item).excerpt}
              uri={switcher(item).uri}
              wordCount={templateSwitcher(item)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default RelatedArticles

RelatedArticles.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
}