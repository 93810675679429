import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../../breadcrumbs'
import parse from 'html-react-parser'
import { Arrow } from '../../../../images/arrow'

const ArticleHeading = ({
  breadcrumbs,
  title,
  excerpt,
  published,
  modified,
  tags,
  image,
}) => {
  return (
    <>
      <div className={'xl-container mx-auto'}>
        <div className={'pt-6 md:pt-18 lg:pt-24'}>
          <Breadcrumbs crumbs={breadcrumbs} />
        </div>
        <div className={'lg:px-[54px] mt-8 md:mt-9 lg:mt-12'}>
          <h1
            className={'text-4xl md:text-5xl font-medium mb-6 md:mb-3 md:mb-8'}
          >
            {title}
          </h1>
          <h2 className={'text-lg mb-6 lg:mb-10 lg:w-9/12'}>
            {parse(excerpt)}
          </h2>
          <div
            className={
              'flex flex-col lg:flex-row flex-wrap justify-between gap-4'
            }
          >
            <div className={'flex justify-start order-last lg:order-first '}>
              <div className={'flex flex-col w-40'}>
                <p className={'text-blue-dark text-lg font-semibold'}>
                  Publisert
                </p>
                <p className={'text-blue-dark'}>{published}</p>
              </div>
              <div className={'flex flex-col w-40'}>
                <p className={'text-blue-dark text-lg font-semibold'}>
                  Sist oppdatert
                </p>
                <p className={'text-blue-dark'}>{modified}</p>
              </div>
            </div>
            <div className={'flex flex-wrap gap-y-2 gap-x-1'}>
              {tags.map((item, index) => (
                <span
                  key={index}
                  className={
                    'pt-2 px-3 pb-2.5 w-max bg-gray-200 rounded-full flex items-center justify-center'
                  }
                >
                  {item.name}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className={''}>
          <img
            className={
              'w-full mx-auto mt-4 md:mt-6 lg:mt-8 rounded-xl aspect-[16/9] object-cover'
            }
            src={image.publicUrl}
            alt={image.altText ? image.altText : ''}
          />
          <div className={'flex gap-x-2 mt-3 lg:mt-5'}>
            {image.caption && (
              <>
                <Arrow direction={'up'} />
                {parse(image.caption)}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ArticleHeading

ArticleHeading.propTypes = {
  breadcrumbs: PropTypes.array,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  published: PropTypes.string,
  modified: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.object,
}
