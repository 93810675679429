import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ArticleHeading from './heading'
import Summary from './summary'
import ArticleContent from './content'
import Freewall from './freewall'
import RelatedArticles from './related-articles'
import { isLoggedIn } from '../../../services/authentication'
import { lockedPageEvent } from '../../google-tag-manager/events'

const Article = ({
  title,
  excerpt,
  published,
  modified,
  tags,
  featuredImage,
  breadcrumbs,
  content,
  introduction,
}) => {
  const { summaryWidget: summary } = content

  const [relatedArticles, setRelatedArticles] = useState([])

  useEffect(() => {
    if (content.relatedCasesList) {
      setRelatedArticles(content.relatedCasesList)
    } else if (content.relatedStatisticsList) {
      setRelatedArticles(content.relatedStatisticsList)
    }
  }, [])

  useEffect(() => {
    isLoggedIn() ? lockedPageEvent(false) : lockedPageEvent(true)
  }, [])

  return (
    <div className={'bg-gray-100'}>
      <ArticleHeading
        title={title}
        excerpt={excerpt}
        tags={tags}
        image={featuredImage}
        breadcrumbs={breadcrumbs}
        modified={modified}
        published={published}
      />

      <Summary
        heading={summary.summaryHeading}
        content={summary.summaryContent}
      />

      {content && (
        <div className={`relative ${!isLoggedIn() && 'hidden'}`}>
          <ArticleContent content={content.mainContent} />
        </div>
      )}

      {relatedArticles.length > 0 && <RelatedArticles list={relatedArticles} />}

      <div className={`${!isLoggedIn() ? 'relative' : 'hidden'}`}>
        <Freewall introduction={introduction} />
      </div>
    </div>
  )
}

export default Article

Article.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  published: PropTypes.string,
  modified: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  featuredImage: PropTypes.object,
  breadcrumbs: PropTypes.array,
  content: PropTypes.object,
  introduction: PropTypes.string,
}